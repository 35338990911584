
.social-media-settings .group-label{
    padding-left: 30px;
}
.social-media-settings .indented{
    padding-left: 30px;
    margin-bottom: 16px;
}

.social-media-settings .buttons{
    display: flex;
    justify-content: end;
    margin-top: 32px;
}

.social-media-settings .chk-group{
    display: flex;
    gap: 8px;
    align-items: center;
}

.social-media-settings .multi-select p{
    font-size: small;
    margin:0;
    font-weight: normal;
}

.social-media-settings .multi-select button{
    min-width: initial;
}

