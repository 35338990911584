.blog-post .body {
    text-align: left;
    margin-bottom: 16px;
    overflow-y: auto;
    max-height: 400px;
}

.blog-post .title-input span{
    max-width: initial;
}

.blog-post .dates{
    font-style: italic;
}
