.product-search-form .filtered button::after {
    content: '';
    width: 8px;
    height: 8px;
    display: block;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    right: 3px;
}

.category-tree {
    max-height: 278px;
    overflow: auto;
}

/* width */
.category-tree::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.category-tree::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.category-tree::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

/* Handle on hover */
.category-tree::-webkit-scrollbar-thumb:hover {
    background: #888;
}


