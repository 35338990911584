.faq-detail .img-container {
    width: 350px;
    text-align: center;
}

.faq-detail img {
    margin: auto;
    max-width: 150px;
    max-height: 150px;
}

.faq-detail .description {
    text-align: left;
    margin-bottom: 16px;
}

.faq-detail table#content-status {
    margin-top: 32px;
    width: 500px !important;
}

.faq-detail .question{
    font-weight: bold;
}
