body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vertical-horizontal-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.center {
  margin: auto;
  width: fit-content;
  text-align: center;
}
.top-bar {
  position: fixed;
  width: 100%;
  z-index: 999;
}
.content {
  padding: 6rem 2rem 2rem 2rem;
}
.relative {
  position: relative;
}
.component-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 8px;
}
.inline {
  display: inline;
}
.no-underline {
  text-decoration: none;
}
#root > div[type="error"][role="alert"] {
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sticky-settings{
  position: sticky;
  top: 64px;
  z-index: 1024
}

.popover-anchor {
  position: fixed;
  bottom: 10px;
  right: 22px;
  width: 50px;
  height: 10px;
}

.popover-container {
  position: relative;
  padding: 8px;
  width: 300px;
}

.popover-container .close-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.popover-container .popover-title {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  /* color: #313440; */
}

.popover-container>div {
  margin-bottom: 16px;
}

.popover-container .popover-footer button {
  width: 100%;
}

div[data-popper-placement=top-end] {
  animation: popoverSlideIn 1s;
  z-index: 99999999999999999999;
  background: #1c1c1c;
  color: #fafafa;
}

@keyframes popoverSlideIn {
  0% {
      bottom: -100%;
  }

  100% {
      bottom: 0;
  }
}
