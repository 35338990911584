.product-select {
    width: 100%;
}

.product-select > div {
    width: 100%;
}

.product-select .menu-item {
    max-width: 500px;
    cursor: pointer;
    background-color: #fff !important;
}

.product-select .menu-item > div {
    padding: 8px;
}

.product-select .menu-item:hover {
    background-color: lightgray;
}
