
.category-settings .group-label{
    padding-left: 30px;
}

.category-settings .indented{
    padding-left: 30px;
    margin-bottom: 16px;
}

.category-settings .buttons{
    display: flex;
    justify-content: end;
    margin-top: 32px;
}

.category-settings .chk-group{
    display: flex;
    gap: 8px;
    align-items: center;
}

