.social-media-post .body {
    text-align: left;
    margin-bottom: 16px;
    overflow-y: auto;
    max-height: 400px;
}

.social-media-post .title-input span{
    max-width: initial;
}

.social-media-post .dates{
    font-style: italic;
}

.social-media-post .description{
    margin-bottom: 16px;
}

.social-media-post .description > div > span{
    max-width: 100%;
}
