.category-detail .img-container {
    width: 350px;
    text-align: center;
}

.category-detail img {
    margin: auto;
    max-width: 350px;
    max-height: 300px;
}

.category-detail .description {
    text-align: left;
    margin-bottom: 16px;
}

.category-detail table#content-status {
    margin-top: 32px;
    width: 500px !important;
}
