.social-media-posts .thumbnail {
    border-radius: 6px;
    max-width: 60px;
    max-height: 60px;
}

.social-media-posts .post-body{
    max-width: 800px;
}

.social-media-post-detail{
    padding: 32px;
}

.social-media-posts .post-actions button{
    min-width: 2rem;
    height: 2rem;
}

.social-media-posts .post-actions button svg{
    width: 1rem;
    height: 1rem;
}
