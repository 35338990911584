.blog-posts .thumbnail {
    border-radius: 6px;
    max-width: 60px;
    max-height: 60px;
}

.blog-posts .blog-title{
    max-width: 600px;
}

.blog-detail{
    padding: 32px;
}
