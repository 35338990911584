.products .thumbnail {
    border-radius: 6px;
    max-width: 60px;
    max-height: 60px;
}

.products .product-name{
    width: 500px;
}

/* div[aria-modal="true"] > div {
    max-width: 85%;
} */
