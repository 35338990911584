.search-form .filtered button::after{
    content: '';
    width: 8px;
    height: 8px;
    display: block;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    right: 3px;
}
